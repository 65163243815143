import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { sendGQLRequest } from "api/graphql/network";
import { GetUiSettingsDocument, UpdateUiSettingsDocument } from "api/graphql";

export function fetchUISettings({ userId, entityId }: Required<UISettingsRequest>) {
  return sendGQLRequest(GetUiSettingsDocument, { user_id: userId, entity_id: entityId }).then(
    (res) => res.ui_settings
  );
}

function updateUISettings({
  userId,
  entityId,
  settings,
}: {
  userId: string;
  entityId: string;
  settings: any;
}) {
  return sendGQLRequest(UpdateUiSettingsDocument, {
    user_id: userId,
    entity_id: entityId,
    settings,
  });
}

/**
 * Use TABLE key prefix for backwards compatibility with old table
 * Use GRID key prefix for newly created components
 */
export enum UISettingsTableEntity {
  AUDIT_LOGS = "GRID_audit-logs",
  DIRECTORIES_LIST = "GRID_directories-list",
  EDM = "EDM-rules-table",
  ENDPOINT_SENSORS = "TABLE_endpoint-sensors",
  INCIDENTS = "GRID_incidents",
  INTEGRATION_CONFIG_GRID = "GRID_integration-config",
  INTEGRATION_CONFIG_LOGS_GRID = "GRID_integration-config-logs",
  INTEGRATION_DESTINATIONS_GRID = "GRID_integration-destinations",
  LIST_VIEW = "TABLE_list-view-table",
  MAPPED_USERS = "GRID_mapped-users",
  MATCHING_LISTS = "GRID_matching-lists",
  NUCLEUZ_POLICIES_LIST = "GRID_nucleuz-policies-list",
  NUCLEUZ_RULES_LIST = "GRID_nucleuz-rules-list",
  RISKY_USERS = "GRID_risky-users",
  SCOPES = "GRID_scopes",
  API_KEYS = "GRID_api-keys",
  USER_PREFERENCES = "GLOBAL_user-preferences",
  EXPOSURE_GRAPH_SUMMARY_NODES = "GRID_exposure-graph-summary-nodes",
  NEW_LOCATIONS_BY_TYPE = "GRID_new-location-by-type",
  POLICIES_PAGE = "GRID_policies-page",
}

const SETTINGS_KEY = "ui_settings";
export const GLOBAL_SETTINGS_ID = "GLOBAL_SETTINGS";
type UISettingsRequest = {
  userId?: string;
  entityId: UISettingsTableEntity;
};

export const useUISettings = <T extends object>({ userId, entityId }: UISettingsRequest) => {
  return useQuery({
    queryKey: [SETTINGS_KEY, entityId, userId],
    enabled: !!userId,
    queryFn: async () => {
      const res = await fetchUISettings({ userId: userId ?? "", entityId });
      const settings = res[0]?.settings as T;

      return {
        ...res[0],
        settings,
      };
    },
    staleTime: Infinity,
    retry: 1,
  });
};

export const useUpdateUISettings = ({
  userId = "",
  entityId,
}: {
  userId?: string;
  entityId: UISettingsTableEntity;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ settings }: { settings: any }) => {
      queryClient.setQueryData([SETTINGS_KEY, entityId, userId], () => ({
        settings,
        entityId,
        userId,
      }));
      return updateUISettings({ userId, entityId, settings });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SETTINGS_KEY, entityId, userId],
      }),
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [SETTINGS_KEY, entityId, userId],
      });
    },
  });
};
